.App {
  background: linear-gradient(180deg, #FF00E5 4.69%, #000000 67.71%);
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100vw;
  overflow: hidden;
}

.navbar {
  display: flex;
  position: fixed;
  width: 100vw;
  max-width: 100vw;
  justify-content: flex-end;
  align-items: center;
  background-color: #222;
  height: 6rem;

  z-index: 999;
  padding: 0 20px;
  color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.nav-logo {
  height: 70px;
  cursor: pointer;
  position: relative;
  top: 0.4rem
}

.nav-icon {
  color: white;
  cursor: pointer;
}

.landingPage {
  display: flex;
  z-index: 1;
}

.closeIcon {
  position: absolute;
  left: 2rem;
  top: 2.9000000000000004rem;
  color: white;
  scale: 2;
}

.bannerWrapper {
  display: flex;
  position: relative;
  width: 100%;
}

.banner {
  flex: 1;

}

.footerWrapper {
  width: 100vw;
  height: 20%;
  position: fixed;
  bottom: 0;

}

.footer {
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #ff00e600 0%, #000000 90%);
}

.menuWrapper {
  display: flex;
  position: fixed;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}

.menu1 {
  background-color: #ff0084;
  height: 33.333vh;
  flex: 1;
  display: flex;
  font-size: 2rem;
  color: #0cff3d;
  justify-content: center;
  font-family: 'Digibop';
  align-items: center;
}

.menu2 {
  background-color: #0cff3d;
  height: 33.333vh;
  font-family: 'Digibop';
  font-size: 2rem;
  flex: 1;
  display: flex;
  color: #ff0084;
  justify-content: center;
  align-items: center;
}

.menu3 {
  background-color: #fff;
  font-size: 2rem;
  color: #0cff3d;
  height: 33.333vh;
  font-family: 'Digibop';

  flex: 1;
  display: flex;

  justify-content: center;
  align-items: center;
}

.hamburger {
  position: absolute;
  left: 2rem;
  width: 10rem;
  font-size: 2rem;

  /* margin-top: 50%; */
  top: 2.2rem;
}

.shoppingCartWrapper {
  width: 5rem;
  height: 5rem;
  margin-right: 3rem;
  margin-top: 50%;
  margin-bottom: 50%;
  display: flex;
  flex-direction: row;
  align-content: center;
  cursor: pointer;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  text-align: center;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
}


.sectionDiv {
  width: 100vw;
  height: 7rem;
  background-image: url(./images/logo.png), linear-gradient(0deg, #000000, #000000);
  background-repeat: repeat-x;
  background-size: auto 100%;
  background-position: 0% center;
  position: relative;
  z-index: 5;
  animation: scrolling-banner 15s linear infinite;
}

@keyframes scrolling-banner {
  0% {
    background-position: 0% center;
  }

  100% {
    background-position: 100% center;
  }
}

.sectionDivTat {
  width: 100vw;
  height: 7rem;
  background-image: url(./images/critterTat.png), linear-gradient(0deg, #000000, #000000);
  background-repeat: repeat-x;
  background-size: auto 100%;
  background-position: 0% center;
  position: relative;
  z-index: 5;
  animation: scrolling-banner 15s linear infinite;
}

@keyframes scrolling-banner {
  0% {
    background-position: 0% center;
  }

  100% {
    background-position: 100% center;
  }
}

@media screen and (max-width: 480px) {
  .nav-icon {
    padding: 3vw;
    color: #0cff3d;
  }

  .shoppingCartWrapper {
    width: 22vw;
    padding-right: 5%;
    display: flex;
    z-index: 10000;
    position: relative;
    height: 100%;
    flex-direction: row;
    align-content: center;

  }

  .nav-logo {
    top: 0.8rem;
  }

}