.merchAppWrapper {
    display: flex;
    width: 100vw;
    flex-direction: column;
    background: linear-gradient(180deg, #F001D8 0%, #FF01E6 53.12%, #4D3248 100%);
    z-index: 1;
    position: relative;
    align-items: center;
}

.titleWrapper {
    margin-top: 5%;
    display: flex;
    flex: 1;

}

.comingSoon {
    font-family: 'DigiBop';
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center;
    font-size: 2rem;
    min-height: 30rem;
    max-height: 30rem;
    margin-top: 8rem;
}

.titleCenter {
    font-family: 'DigiBop';
    font-style: italic;
    position: relative;
    font-weight: 400;
    font-size: 15vw;
    color:white;
    z-index:20;
    line-height: 180px;
}
.titleLeftMerch {
    font-family: 'DigiBop';
    font-style: italic;
    font-weight: 400;
    z-index: 10;
    position: relative;
    left:33%;
    color: #1ee242;
    font-size: 15vw;
    line-height: 180px;
}
.titleRightMerch {
    font-family: 'DigiBop';
    font-style: italic;
    font-weight: 400;
    z-index: 30;
    position: relative;
    right:33%;
    color: black;
    font-size: 15vw;
    line-height: 180px;
}

.managerWrapper {
    flex: 1;
    display: flex;
}

.manager {
    position: absolute;
    width: 20%;
    height: 448.1px;
    left: -86px;
    top: 1859.94px;
    
}

.merchItemWrapper {
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20vw;
    padding-bottom: 5rem;
    padding-left: 2vw;
    padding-left: 2vw;
    padding-top: 2rem;
    flex-shrink: 0; /* Add this line */
}

.merchItemOuter {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: black;
    padding-bottom: 10%; /* Add this line */
    padding-top: 10%;
    position: relative; /* Add this line */
}



.imageContainerMerch{
    position: absolute;
    width: 100%;
    height: 100%;
}

.managerMerch{
    position: absolute;
    left:0%;
    top:10%;
    animation: rocking 2s linear infinite;
    transform-origin: center;
    filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.5));
    width: 35%;


}
@keyframes rocking {
    0% {
      transform: rotate(-5deg);
    }
    50% {
      transform: rotate(5deg);
    }
    100% {
      transform: rotate(-5deg);
    }
  }

  .animatedImage {
    position: fixed;
    width: 50px;
    height: 50px;
    z-index: 9999;
    pointer-events: none;
  }
  
  

.cdMerch{
    position: absolute;
    top:25%;
    right:10%;
    animation: rocking 2s linear infinite;
    transform-origin: center;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.5);
    width:20%


}

.merchItemInner {
    width: 80%;
    aspect-ratio: 1;
    background-color: rgb(255, 255, 255);
    width: '100%';
    height: '100%';
    background-size: 'cover';
    background-position: 'center';
    
}

.merchImage {
    width: 100%;
}

.merchTitleWrapper {
    display: flex;
    flex: 1;
}

.merchTitle {
    font-family: 'DigiBop';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 56px;
    text-align: center;

    color: #FFFFFF;
}

.merchPriceWrapper {
    display: flex;
    flex: 1;

}

.merchPrice {
    font-family: 'DigiBop';
    font-style: normal;
    font-weight: 400;
    font-size: 70px;
    line-height: 98px;
    text-align: center;

}

.addToCartWrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
}

.addToCartButton {
    background: linear-gradient(0deg, #46003F, #46003F), linear-gradient(0deg, #46003F, #46003F), #46003F;
    width: 15vw;
    height: 2rem;
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 20;
    font-size: 1vw;
    line-height: 20px;
    color: #FFFFFF;
    margin-top: 1rem;
}

.addToCartText {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 20;
    font-size: 1vw;
    line-height: 20px;
    color: #FFFFFF;
}

.merchCollectionWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 70%;
    justify-content: center;
}

.absoluteManager {
    position: absolute;
    width: 410.13px;
    height: 448.1px;
    top: 4rem;
    left: 1vw;
    z-index: 4;
}
.absoluteCD {
    position: absolute;
    width: 20%;
    top: 5rem;
    right: 1vw;
    z-index: 4;
}
.merchItemInner {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .merchItemImage {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  @media screen and (max-width: 480px) {
    .merchItemWrapper {
        position: relative;
        z-index: 5;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 47vw;
        padding-bottom: 7rem;
        padding-left: 2vw;
        
        padding-top: 3rem;
    }
    .addToCartButton {
        background: linear-gradient(0deg, #46003F, #46003F), linear-gradient(0deg, #46003F, #46003F), #46003F;
        width: 30vw;
        height: 2.5rem;
        font-family: 'Roboto Mono';
        font-style: normal;
        font-weight: 20;
        font-size: 3vw;
        line-height: 20px;
        color: #FFFFFF;
    }
    
    .titleCenter{
        font-size: 30vw;
        bottom:0;
        line-height: 8rem;
    }
    .titleLeftMerch{
        font-size: 30vw;
        bottom: 0;
        line-height: 8rem;
    }
    .titleRightMerch{
        font-size: 30vw;
        bottom:0;
        line-height: 8rem;
    }
    .managerMerch{
        left:-15%;
        width: 60%;
    }
    .cdMerch{
        left:73%;
        width:26%;
    }
    .comingSoon {
        margin-top: 13rem;
    }
}

