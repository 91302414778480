.musicAppWrapper {
  display: flex;
  width: 100vw;
  height: 100%;
  flex-direction: column;
  background: linear-gradient(180deg, #FF01E6 0%, #FF01E6 53.12%, #4D3248 100%);
  z-index: 1;
  position: relative;
  align-items: center;
}

.titleWrapper {
  display: flex;
  flex: 1;
  max-height: 15rem;
}

.titleMusic {
  height: fit-content;
  display: flex;
  font-family: 'DigiBop';
  font-style: italic;
  position: relative;
  font-weight: 400;
  font-size: 16vw;
  color: white;
  z-index: 20;
  line-height: 5rem;
  top: 5rem;
  min-height: 14rem;
}

.titleLeftMusic {
  font-family: 'DigiBop';
  font-style: italic;
  font-weight: 400;
  z-index: 10;
  position: relative;
  left: 33%;
  color: #1ee242;
  font-size: 16vw;
  line-height: 5rem;
  top: 5rem;
  min-height: 14rem;
}

.titleRightMusic {
  font-family: 'DigiBop';
  font-style: italic;
  top: 5rem;
  min-height: 14rem;
  font-weight: 400;
  z-index: 30;
  position: relative;
  right: 33%;
  color: black;
  font-size: 16vw;
  line-height: 5rem;
}



.musicVideoWrapper {
  background-color: #6e9988;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem;
  padding-left: 5%;
  padding-right: 5%;
  box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.3);
  aspect-ratio: 16/9;


}

.youtubeVideo {
  width: 20vw;
  aspect-ratio: 16/9;
}

.musicVideoContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 2%;
  margin-bottom: 2%;
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}


.musicVideoContainerHovered {
  width: 40vw;
  background-color: #db078f;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.3);
  aspect-ratio: 16 / 9;
}



.music {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 50%;
  min-height: 30rem;
  position: relative;
}

.slide {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.5s cubic-bezier(0.6, 0, 0.5, 1);
  /* Add this line for the sliding animation */
}


.videoWrapper {
  display: flex;


}

.video {
  display: flex;
  aspect-ratio: 16/9;
  max-width: 100%;
  max-height: 100%;
}

.motionDiv {
  position: absolute;
}


.arrow {
  background: none;
  border: none;
  font-size: 2rem;
  z-index: 3000;
  top: -5rem;
  position: relative;
  cursor: pointer;
  font-family: 'digibop';
}

.leftArrow {
  right: 45%;
  scale: 4;
  max-height: 2rem;
}

.leftArrowPlaying {
  background: none;
  border: none;
  font-size: 2rem;
  z-index: 3000;
  position: relative;
  cursor: pointer;
  font-family: 'digibop';
  right: 5%;
  scale: 4;
  display: none;
}



.rightArrow {
  left: 45%;
  scale: 4;
  max-height: 2rem;
}

.rightArrowPlaying {
  background: none;
  border: none;
  font-size: 2rem;
  z-index: 3000;
  position: relative;
  cursor: pointer;
  font-family: 'digibop';
  left: 5%;
  scale: 4;
  display: none;
}

.spinningDiskWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.5));
  top: 5rem;
  width: 100%;
  padding-bottom: 20%;

}

.cdWrapper {
  position: relative;
  width: 22rem;
  height: 22rem;
}

.cdImageMusic {
  position: absolute;
  top: 0;
  margin-bottom: 20%;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-position: center;
  border-style: solid;
  border-color: white;
  border-width: 5%;
  background-size: contain;
  background-image: radial-gradient(circle at center, transparent 30px, white 31px, white 32px, transparent 32px);
  mask-image: radial-gradient(circle at center, transparent 30px, black 31px);
  animation: rotate 1.5s cubic-bezier(0.6, 0, 0.5, 1) infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.songTitle {
  display: flex;
  justify-content: center;
  text-align: center;
  font-family: 'digibop';
  color: white;
  -webkit-text-stroke: 5px rgb(0, 0, 0);
  /* Updated stroke effect */
  width: 100%;
  line-height: 5vw;
  font-size: 5vw;
  position: relative;
}


.innerCdHole {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  background-color: transparent;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

@keyframes opacity {
  from {
    transform: opacity(0);
  }

  to {
    transform: opacity(100);
  }
}

.playButton {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  transform-origin: center;
  align-items: center;
}

.kirby {
  position: absolute;
  left: 5rem;
  top: 9rem;
  width: 12rem;
  opacity: 0;
}

.kirby2 {
  position: absolute;
  right: 5rem;
  top: 9rem;
  width: 12rem;
}

.playButtonText {
  font-family: 'Digibop';
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  font-size: 2vw;
  line-height: 2%;
  border-style: solid;
  width: 5%;
  padding: 2vw;
  border-width: 0.7rem;
  border-color: black;
  margin-top: 3rem;
  margin-bottom: 2rem;

}

.innerPlay {
  scale: 3;
}

.innerStop {
  scale: 3;
}

@media screen and (max-width: 480px) {

  .titleMusic {
    font-size: 30vw;
    bottom: 0;
    max-height: 1rem;
  }

  .titleLeftMusic {
    font-size: 30vw;
    bottom: 0;
    max-height: 1rem;
  }

  .titleRightMusic {
    font-size: 30vw;
    bottom: 0;
    max-height: 1rem;
  }

  .songTitle {
    display: flex;
    justify-content: center;
    text-align: center;
    font-family: 'digibop';
    color: white;
    -webkit-text-stroke: 5px rgb(0, 0, 0);
    width: 100%;
    line-height: 10vw;
    font-size: 15vw;
    position: relative;
  }

  .playButtonText {
    margin-bottom: 20%;
    width: 20vw;
    display: flex;
    border-style: none;
    z-index: 2000;
    position: relative;

  }


  .playButtonText {
    font-size: 6vw;
  }

  .musicVideoWrapper {
    background-color: transparent;
    box-shadow: none;
    position: absolute;
  }

  .playButton {
    max-height: 4rem;
    justify-content: baseline;
  }


  .musicAppWrapper {
    height: 50rem;
  }

  .leftArrow {
    right: 50%;
    scale: 2;
    top: -18rem;
    color: black;
    max-height: unset;

  }

  .rightArrow {
    left: 50%;
    scale: 2;
    top: -18rem;
    color: black;
    max-height: unset;
  }

  .leftArrowPlaying {
    display: none;
  }

  .rightArrowPlaying {
    display: none;
  }

  .cdWrapper {
    width: 80vw;
    height: 80vw;
  }

  .youtubeVideoPlayer {
    position: absolute;
    z-index: 2;
  }

  .kirby2 {
    display: hidden;
    opacity: 0;
  }

  .kirby {
    opacity: 1;
    position: absolute;
    top: 1rem;
    width: 6rem;
    left: 19rem;
  }





}