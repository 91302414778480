.content {
  flex-grow: 1;
  padding-top: 10%;
  padding-left: 5%;
  padding-right: 5%;
  height: 100%;
  max-width: 100vw;
  overflow: hidden;

}

h1 {
  font-family: 'DigiBop', 'Roboto';
}

.titletext {
  position: relative;
  font-family: 'DigiBop';
  font-style: italic;
  font-weight: 400;
  font-size: 12vw;
  z-index: 2;
  line-height: 120%;
  color: #FFFFFF;
  stroke-width: 8px;
  text-shadow: -8px -8px 0 black, 8px -8px 0 black, -8px 8px 0 black, 8px 8px 0 black,
    /* Corners */
    8px 16px 8px black, -8px -16px 8px black, 16px 8px 8px black, -16px 8px 8px black,
    /* Sides */
    16px 16px 0 black, 16px -16px 0 black, -16px 16px 0 black, -16px -16px 0 black,
    /* Diagonals */
    4px 16px 0 black, 4px -16px 0 black, -4px 16px 0 black, -4px -16px 0 black,
    /* Inside corners */
    16px 2px 0 black, 16px -2px 0 black, -16px 2px 0 black, -16px -2px 0 black;
  /* Inside corners */
}

.welcomeText {
  font-family: 'DigiBop';
  font-style: italic;
  font-weight: 400;
  font-size: 4vw;
  line-height: 120%;
  z-index: 2;

  color: #FFFFFF;




}

.imagesContainer {
  display: flex;
  position: relative;
  z-index: 1;
  max-height: 200%;
}

.linkGif {
  position: absolute;
  left: -1rem;
  top: 4rem;
  z-index: 2;
  width: 20vw;
}

.flexImage {
  flex: 1;
  z-index: 1;
}

.fixedImage {
  display: block;

}

.flexImageBehind {
  flex: 1;
  min-width: 20%;
  width: 0%;
  z-index: 0;
}

.flexText {
  flex: 1;
  min-width: 20%;
  max-height: fit-content;
  z-index: 2;
}

.gameboy {
  position: absolute;
  width: 45%;
  left: 10%;
  right: 30%;
  filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.5));

  top: -20%;
  z-index: 1;
}

.ipod {
  position: absolute;
  width: 25%;
  filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.5));

  left: 45%;
  top:10%;
  margin-top: 10%;

  z-index: 2;
}

.textContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  margin-top: 5rem;

}

.manger {
  position: absolute;
  bottom: -150%;
  left: 200%;
  width: 200%;
  max-height: fit-content;

  filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.5));

  z-index: 3;
}

.gradient {

  position: absolute;
  z-index: 0;
  opacity: 80%;
  width: 25rem;
  right: -10%;
  top: -100%;

}

.textHeartWrapper {
  display: flex;
  filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.5));


  width: 25%;
  z-index: 1;

}

.textHeart {
  position: relative;
  bottom: 70%;
  right: 70%;
  width: 60%;

  z-index: 1;
}

.bioContent {
  margin-top: 30%;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 50vw;
  margin-bottom: 3rem;
}

.bioTitle {
  font-family: 'Roboto Mono', monospace;
  font-style: normal;
  font-weight: 1000;
  font-size: 8rem;
  line-height: 112px;
  color: #FF01E6;
  white-space: nowrap;
  margin-bottom: 2rem;
  z-index: 3;
}

.bioText {
  font-family: 'Roboto Mono', monospace;
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 33px;
  color: #FFFFFF;
  z-index: 3;
}

.bioTextDigi {
  font-family: 'digibop';
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 33px;
  color: #FFFFFF;
  z-index: 3;
}

.heartBalloon {
  transform: rotate(-25.78deg);
  z-index: 0;
  left: 80%;
  width: 20vw;
  margin-top:-20%;
  margin-bottom: 30%;
  position: absolute;
}

.animatedHeart {
  z-index: 1;
  position: absolute;
  width: 20%;
  margin-bottom: 120%;
  margin-top: -30%;
}

.critterNet {
  z-index: 0;
  position: absolute;
  left: -5vw;
  bottom: 130%;
  width: 0%
}

.cdImage {
  z-index: 0;
  position: absolute;
  right: 5%;
  width: 10%;
  top: 50rem;
  animation: rotate 7s linear infinite;
  /* Add this line to apply rotation animation */
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 480px) {
  .textContainer {
    top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .welcomeText {
    stroke: none;
    font-size: 6vw;
    text-shadow: -4px -4px 0 black, 4px -4px 0 black, -4px 4px 0 black, 4px 4px 0 black,
      /* Corners */
      0 4px 0 black, 0 -4px 0 black, 4px 0 0 black, -4px 0 0 black,
      /* Sides */
      4px 4px 0 black, 4px -4px 0 black, -4px 4px 0 black, -4px -4px 0 black,
      /* Diagonals */
      4px 4px 0 black, 4px -4px 0 black, -4px 4px 0 black, -4px -4px 0 black,
      /* Inside corners */
      4px 4px 0 black, 4px -4px 0 black, -4px 4px 0 black, -4px -4px 0 black;
    /* Inside corners */
  }

  .titletext {
    stroke: none;
    text-shadow: -6px -6px 0 black, 6px -6px 0 black, -6px 6px 0 black, 6px 6px 0 black,
      /* Corners */
      0 6px 0 black, 0 -6px 0 black, 6px 0 0 black, -6px 0 0 black,
      /* Sides */
      6px 6px 0 black, 6px -6px 0 black, -6px 6px 0 black, -6px -6px 0 black,
      /* Diagonals */
      6px 6px 0 black, 6px -6px 0 black, -6px 6px 0 black, -6px -6px 0 black,
      /* Inside corners */
      6px 6px 0 black, 6px -6px 0 black, -6px 6px 0 black, -6px -6px 0 black;
    /* Inside corners */
    font-size: 18vw;
  }

  .bioTitle {
    font-size: 20vw;
    line-height: 1.5rem;

  }

  .bioContent {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90vw;
    margin-top: 26rem;
  }

  .bioText {
    font-size: 2rem;
    text-align: center;
    font-weight: 800;
    line-height: 2.2rem;
  }

  .linkGif {
    position: absolute;
    left: -1vw;
    top: 14rem;
    z-index: 2;
    width: 42vw;
  }

  .gradient {
    width: 80vw;
    opacity: 30%;
  }

  .gameboy {
    top: 8.3rem;
    width: 90%;
    left: 5%;
  }

  .ipod {
    top: 13rem;
    width: 50%;
    left: 55%;
    margin-top: 7%;

  }

  .manger {
    left: -4vw;
    width: 30%;
    bottom: -46rem;
  }

  .animatedHeart {
    right: -2rem;
    position: absolute;
    width: 25%;
    z-index: 1;
    top: 2rem;
  }

  .heartBalloon {
    left: 0%;
    position: absolute;
    top:-4rem;
    -webkit-transform: rotate(-25.78deg);
    transform: rotate(-25.78deg);
    width: 30%;
    z-index: 0;
  }

  .imagesContainer {
    max-height: 0;
  }


}